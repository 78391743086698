<template>
    <v-app>
    <div class="reserve-form" style="margin-top:150px">
        <!-- <div v-if="alert" class="alert alert-danger alert-position" role="alert">
                {{text}}
            </div> -->
        <h3 class="reserv-title mb-5">Registration Form </h3>
            <v-form
                ref="form"
                @submit="UserReg"
                v-model="valid"
                :lazy-validation="lazy"
                class="pa-3"
            >
            <v-text-field
                v-model="user.fullName"
                :rules="[
                v => !!v || 'Name is required',]"
                label="Full Name*"
                outlined
                dense
                required
            ></v-text-field>
            
            <v-text-field
                v-model="user.email"
                :rules="[
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',]"
                label="Email*"
                outlined
                dense
                required
            ></v-text-field>
            
            <v-text-field
                v-model="user.phone"
                :rules="[
                v => !!v || 'Phone is required',
                v => /^\d{6}/.test(v) || 'Phone number must be valid',]"
                label="Phone*"
                outlined
                dense
                required
            ></v-text-field>
            <v-text-field
                v-model="user.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[v => !!v || 'Password is required',v => v.length >= 6 || 'Min 6 characters']"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password*"
                dense
                outlined
                @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
                v-model="user.password_confirmation"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="conPassRules"
                :type="show2 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirm Your Password*"
                outlined dense
                @click:append="show2 = !show2"
            ></v-text-field>
             <select style="margin-bottom:26px" v-model="user.gender" class="custom-select">
                <option value="" disabled selected >Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
            </select>
            <v-text-field
                v-model="user.address"
                label="Address"
                outlined
                dense
            ></v-text-field>
            <!-- <v-row>
            <v-col cols="12">
               
            <v-select
                class="mt-4"
                v-model="user.gender"
                :items="genders"
                label="Select gender"
                outlined
                dense
            ></v-select>
            
            </v-col></v-row> -->
            
            <!-- <div class="checkbox">
                <v-checkbox
                    class="mb-5"
                    color="info"
                    value="value"
                    hide-details
                > </v-checkbox>
                <span class="position">Remember Me</span>
            </div> -->
            
            <v-btn
                small
                block
                :disabled="!valid"
                style="color:#fff"
                color="deep-orange darken-2"
                @click="UserReg()"
                :loading="loading"
            >
                Submit
            </v-btn>
            <p class="float-left mt-2" style="font-size:14px;"><router-link :to="{name: 'signIn'}"> Already have an account ? <span style="color:#e54c2a">Log in</span> </router-link></p>
            <p class="float-right mt-2" style="font-size:14px;"><router-link :to="{name: 'passwordReset'}"> <span style="color:#e54c2a">Forgot Password?</span> </router-link></p>
        </v-form>
        <notifications group="signUp" position="top left" />
        <!-- snackbar -->
            <v-snackbar v-model="snackbar">
                {{ text }}
                <v-btn color="pink" text  @click="snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        <!-- snackbar -->
    </div>
</v-app>
</template>

<script>
    export default {
        name: "signUp",
        data() {
            return {
                show1: false,
                show2: false,
                text: '',
                snackbar: false,
                lazy: false,
                valid: true,
                loading: false,
                user:{
                    fullName:'',
                    email:'',
                    phone:'',
                    address: '',
                    password:'',
                    gender: '',
                    password_confirmation:''
                },
                genders: ['male', 'female', 'other'],
                conPassRules: [
                  v => !!v || 'Name is required',
                  () => this.user.password === this.user.password_confirmation || 'Password must match',
                ],
            }
        },
        computed: {
            passwordConfirmationRule() {
                return () => (this.user.password === this.user.password_confirmation)
            },
        },
        methods: {
            UserReg(){
                this.loading = true
                 this.axios.post('/register',
                       {
                        name:this.user.fullName,
                        email:this.user.email,
                        password:this.user.password,
                        phone:this.user.phone,
                        address:this.user.address,
                        gender:this.user.gender,
                        password_confirmation:this.user.password_confirmation})
                .then(res=>{
                     //alert(res);
                      let logRes=res.data.success;
                     if(!logRes){
                         this.loading = false
                        //alert('Please input valide email address and password');
                        this.snackbar = true
                        this.text = res.data.message
                        // this.$notify({
                        //     group: 'signUp',
                        //     type: 'error',
                        //     title: res.data.message,
                        //     //text: 'Hello user! This is a notification!'
                        // });
                     }
                     else{
                        localStorage.setItem('userToken',res.data.data.token);
                        localStorage.setItem('fk_uses_id',res.data.data.user.id);
                        this.$store.commit('setUserAfterLogIN')
                        this.$notify({
                            group: 'signUp',
                            type: 'success',
                            title: 'Registration successful',
                            text: 'Your are logged in now'
                        });
                        // this.$router.push({name:"home"})
                        this.$refs.form.reset()
                        this.$refs.form.resetValidation()
                        this.redirect()
                     }

                })
            },
            notify(){
                this.$notify({
                    group: 'cart',
                    type: 'error',
                    title: 'Added to Cart',
                    //text: 'Hello user! This is a notification!'
                });
            },
            redirect(){
                setTimeout(() => {  this.$router.push({name:"Home"}); }, 3000);
            }
        },
        mounted() {
            var el = document.querySelector(".v-application--wrap");
            el.classList.add("mystyle");
        }
    }
</script>

<style scoped>
    .theme--light.v-application {
    background: transparent;
        color: rgba(0, 0, 0, 0.87);
    }
    @media only screen and (max-width: 768px){
        .reserv-title {
            margin-top: 0px;
        }
    }
</style>